import { Location } from "history";
import { initialState, AppState } from "./reducer";
import { createSelector } from "reselect";

const selectAppState = (state: any): AppState => state.main || initialState;

const selectCurrentRouter = (state: any): any => state.router;

const selectCurrentLocation = createSelector(
  selectCurrentRouter,
  (router: any): Location => router.location
);

const selectCurrentPath = createSelector(
  selectCurrentLocation,
  (location: Location): string => location.pathname
);

const selectCurrentParams = createSelector(
  selectCurrentLocation,
  (location: Location): string => location.search
);

const selectLocale = createSelector(
  selectAppState,
  (state: AppState) => state.locale
);

const selectStatuses = createSelector(
  selectAppState,
  (state: AppState) => state.statuses
);

export {
  selectAppState,
  selectCurrentLocation,
  selectCurrentPath,
  selectCurrentParams,
  selectLocale,
  selectStatuses
}
