type RGB = {
  r: number;
  g: number;
  b: number;
}

export const getColorGradient = (color1: RGB, color2: RGB, percent: number, format: "string" | "raw"): RGB | string => {
  const red = color1.r + percent * (color2.r - color1.r);
  const green = color1.g + percent * (color2.g - color1.g);
  const blue = color1.b + percent * (color2.b - color1.b);

  return format === "raw" ? {
    r: red,
    g: green,
    b: blue
  } : `rgb(${red},${green},${blue})`;
}
